/*
 * priorityNav » Rob Hewitt
 * version 1.0
 * © 2016 Steadfast Design Firm
 */

(function($){
	// default options
	var defaults = {
			breakpoint: 767, //calculations stop at this size and smaller
			fullWidth: false, //when true, space available is strictly calculated on the links container
			sensitivity: 50,
			activeClass: 'active',
			currentClass: 'current'
		};

	// attach prioritization calcuations to window resize, and trigger on initial attachment
	function assignPrioritize($this, options) {
		var opts = $.extend(defaults, options);

		// rePrioritize($this, opts);

		$(window).resize(function() {
			rePrioritize($this, opts);
		});
	}

	//actual prioritization calculations
	function rePrioritize($this, opts){

		if($(window).width() > opts.breakpoint) {

			if($this.children('.more').length === 0) {
				$this.children().one().parent().append('<li class="more" style="display: none;"><a href="#">More</a><ul></ul></li>');
			}

			var navWidth = $this.width(),
				moreWidth = $this.children('.more').outerWidth() + parseFloat($this.children('.more').css('margin-left')) + parseFloat($this.children('.more').css('margin-right')),
				availableWidth = navWidth - moreWidth - opts.sensitivity,
				linksWidth = 0;

			if(!opts.fullWidth) {

				var parentWidth = $this.parent().width(),
					siblingsWidth = 0,
					navMargin = parseFloat($this.css('margin-left')) + parseFloat($this.css('margin-right'));

				$this.siblings().each(function(){
					var thisPosition = $(this).css('position').replace('!important','').replace(' ', ''),
						thisWidth = $(this).outerWidth() + parseFloat($(this).css('margin-left')) + parseFloat($(this).css('margin-right'));

					if(thisPosition === 'static' || thisPosition === 'relative') {
						siblingsWidth += thisWidth;
					}
				});

				navWidth = parentWidth - siblingsWidth - navMargin;
				availableWidth = navWidth - moreWidth - opts.sensitivity;

			}

			$this.children(':not(.more)').each(function() {

				var childWidth = $(this).outerWidth() + parseFloat($(this).css('margin-left')) + parseFloat($(this).css('margin-right'));

				//add width to total nav width
				linksWidth += childWidth;

			});

			if(linksWidth > availableWidth) {

				var $lastPriority =	$this.children(':not(.more)').last(),
					lastPriorityWidth = $lastPriority.outerWidth() + parseFloat($lastPriority.css('margin-left')) + parseFloat($lastPriority.css('margin-right'));

				//store elements width to reference later when deprioritizing, move to more menu
				$lastPriority.attr('data-width', lastPriorityWidth).prependTo($this.children('.more').children('ul'));

				//make sure "more" is visible
				$this.children('.more').css('display','');

				//loop back through
				rePrioritize($this, opts);

			} else { //more space available than current used

				var $newPriority = 	$this.children('.more').children('ul').children(':not(.more)').one(),
					newPriorityWidth = 0;

				$newPriority.each(function(){
					var childWidth = Number($(this).attr('data-width'));

					newPriorityWidth += childWidth;
				});

				if (linksWidth + newPriorityWidth < availableWidth && newPriorityWidth > 0) {

					$newPriority.each(function(){
						$(this).attr('data-width', '').insertBefore($this.children('.more'));
					});

					//loop back through
					rePrioritize($this, opts);

				} else {

					if($this.children('.more').children('ul').children('li').length === 0) {
						$this.children('.more').remove();
					}

				}
			}

			// if "more" has links that are active, give "more" active link too
			var numberActive = 0;

			$this.children('.more').children('ul').children('li').each(function(){
				if($(this).hasClass(opts.activeClass) || $(this).hasClass(opts.currentClass)) {
					numberActive++;
				}
			});

			if(numberActive > 0) {
				$this.children('.more').addClass(opts.activeClass);
			} else {
				$this.children('.more').removeClass(opts.activeClass);
			}
		}
	}

	$.fn.prioritize = function(options){
		return this.each(function(){
			var $this = $(this);

			// if plugin is already attached to the element, do not attach again
			if( $this.hasClass('prioritized') ) { return; }

			$this.addClass('prioritized');

			// call the constructor method
			assignPrioritize($this, options);
		});
	};

	//initialize if elements have shorthand class

	$('.prioritize').each(function() {
		$(this).prioritize();
	});

})(jQuery);
